<template>
  <div class="login-container">
    <form
      autocomplete="off"
      class="form-signin"
      @submit.stop.prevent="onSubmit"
    >
      <router-link
        to="/"
        v-b-tooltip.hover
        :title="$t('NAV_HEADER.back_to_home')"
        class="my-4 d-block"
      >
        <b-img src="/fightback-logo-white.png" height="40"></b-img>
      </router-link>

      <h3 class="form-signin-heading">{{ $t("PAGE_LOGIN.login_title") }}</h3>
      <b-input
        :placeholder="$t('PAGE_LOGIN.email_placeholder')"
        class="form-control"
        type="email"
        id="textEmail"
        v-model="user.email"
        required
      />
      <b-input
        :placeholder="$t('PAGE_LOGIN.password_placeholder')"
        class="form-control"
        type="password"
        id="textPassword"
        v-model="user.password"
        required
      />

      <b-button block size="lg" type="submit" variant="primary">
        {{ $t("PAGE_LOGIN.login_submit") }}
      </b-button>
    </form>

    <div class="register-box">
      <h4>{{ $t("PAGE_LOGIN.non_user_title") }}</h4>

      <router-link class="btn btn-block btn-lg btn-light" to="/signup">
        {{ $t("PAGE_LOGIN.non_user_signup_now") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  metaInfo() {
    return {
      title: "Entrar",
    };
  },
  data: function () {
    return {
      user: {},
    };
  },
  methods: {
    onSubmit() {
      var self = this;
      this.$http
        .post("user_sessions.json", { user: this.user })
        .then((response) => {
          var token = response.data.token;
          if (token) {
            localStorage.setItem("token", token);
            this.$store
              .dispatch("login", { token: token, user: response.data.user })
              .then(() => {
                if (response.data.user.nickname == null) {
                  this.$notify({
                    group: "all",
                    text:
                      "Bem-vindo ao FIGHTBACK. Antes de continuar, preencha seu perfil :)",
                    type: "success",
                    duration: 6000,
                  });
                  this.$router.push(`/users/${response.data.user.id}/edit`);
                } else if (this.$route.query.returnUrl)
                  this.$router.push(this.$route.query.returnUrl);
                else this.$router.push("/");
              });
          }
        });
    },
  },
};
</script>


<style lang="sass" scoped>
.login-container
  .form-signin
    max-width: 340px
    margin: 60px auto 10px

    .form-signin-heading
      color: rgba(255, 255, 255, .85)
      margin-bottom: 10px
      font-weight: 500

    .checkbox
      font-weight: normal

    .form-control
      position: relative
      font-size: 16px
      height: auto
      padding: 10px
      background: rgba(255, 255, 255, .1)

    input[type="email"]
      margin-bottom: -1px
      border-top: 1px solid transparent
      border-right: 1px solid transparent
      border-left: 1px solid transparent
      border-bottom: 0
      box-shadow: none
      border-radius: 3px 3px 0px 0px
      &:focus
        color: white

    input[type="password"]
      z-index: 2
      margin-bottom: 20px
      border-top: 0
      border-bottom: 0
      border-right: 1px solid transparent
      border-left: 1px solid transparent
      border-radius: 0px 0px 3px 3px
      box-shadow: inset 0 1px 1px rgba(0,0,0,0.075)
      &:focus
  .btn
    border-radius: 3px
    border: none
    font-weight: bold
    opacity: .45
    transition: all .2s
    &.btn-primary
      background: $danger
    &:hover
      opacity: 1
    i.fab
      font-weight: normal

  .register-box
    max-width: 340px
    margin: 40px auto 10px
    padding-top: 30px
    border-top: 1px solid rgba(255, 255, 255, .2)
    h4
      color: rgba(255, 255, 255, .75)
      font-weight: 500
    .btn
      background: rgba(255, 255, 255, .2)
      color: rgba(255, 255, 255, .7)
      transition: all .2s
      &:hover
        background: $danger
        color: white
</style>
